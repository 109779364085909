import React, { useState } from "react";
import { Grid } from "@mui/material";
import SalesOverview from "../../../shared/metrics/SalesOverview";
import Documents1 from "../../../shared/widgets/Documents1";
import Ideas1 from "../../../shared/widgets/Ideas1";
import UserOrders from "../../../shared/metrics/UserOrders";
import UserSummary from "../../../shared/widgets/UserSummary";
import ScheduleCard from "../../../shared/widgets/ScheduleCard";
import UserProfileCard1 from "../../../shared/widgets/UserProfileCard1";
import NewConnections from "../../../shared/widgets/NewConnections";
import RecentActivities from "../../../shared/widgets/RecentActivities";
import LastMonthSales from "../../../shared/metrics/LastMonthSales";
import OnlineSignupsFilled from "../../../shared/metrics/OnlineSignupsFilled";
import NewVisitorsThisMonth from "../../../shared/metrics/NewVisitorsThisMonth";
import TotalRevenueThisYear from "../../../shared/metrics/TotalRevenueThisYear";
import UpgradePlan from "../../../shared/widgets/UpgradePlan";
import SalesReport1 from "../../../shared/metrics/SalesReport1";
import WordOfTheDay from "../../../shared/widgets/WordOfTheDay";
import OurOffice1 from "../../../shared/widgets/OurOffice1";
import UserProfileAction from "../../../shared/widgets/UserProfileAction";
import PopularProducts from "../../../shared/widgets/PopularProducts";
import ProjectsListCard from "../../../shared/widgets/ProjectsListCard";
import ProductImage from "../../../shared/widgets/ProductImage";
import ExplorePlaceCard from "../../../shared/widgets/ExplorePlaceCard";
import MarkerClusterer from "../../maps/MarkerClusterer";
import AppUsers from "../../../shared/metrics/AppUsers";
import CafeStore1 from "../../../shared/widgets/CafeStore1";
import LatestAlerts from "../../../shared/widgets/LatestAlerts";
import DailyFeed from "../../../shared/widgets/DailyFeed";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { useJumboApp } from "@jumbo/hooks";
import api from "../../../services/config";
import { useSelector } from "react-redux";
import DashboardSkeleton from "./Skeleton";
import ChamadosResolvidosDiretos from "app/shared/metrics/ChamadosResolvidosDiretos/ChamadosResolvidosDiretos";
import ProjectCounterCard from "app/shared/widgets/ProjectCounterCard";

const MiscDashboard = () => {
  const { setActiveLayout } = useJumboApp();

  const { auth } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [resumoChamados, setResumoChamados] = useState([]);
  const [tecnicosPendentes, setTecnicosPendentes] = useState([]);
  const [ultimasAtividades, setUltimasAtividades] = useState([]);
  const [contagemTickets, setContagemTickets] = useState(null);

  const labelFormated = (label) => {
    const lb = label.split("-");

    return `${lb[1]}/${lb[0]}`;
  };

  async function fetchDashboard() {
    setLoading(true);
    try {
      const { data } = await api.get("/api/v2/dashboard", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      console.log("DASHBOARD > > > ", data);

      const temp = [];

      Object.keys(data.resumoTickets).map((key) => {
        temp.push({
          hKey: labelFormated(key),
          ...data.resumoTickets[key],
        });
      });

      setResumoChamados(temp);
      setTecnicosPendentes(data.tecnicosPendentes);
      setUltimasAtividades(data.ticketsAlterados);
      setContagemTickets(data.contagemTickets);
    } catch (error) {
      console.log("ERROR > > ", error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);

    console.log("AUTH > ", auth);
    fetchDashboard();
  }, []);

  return (
    <>
      {loading ? (
        <DashboardSkeleton />
      ) : (
        <Grid container spacing={3.75}>
          <Grid item xs={12} /* lg={9} */>
            <SalesOverview data={resumoChamados} />
          </Grid>

          {/* <Grid item xs={12} lg={3}>
                            <Grid container spacing={3.75}>
                            <Grid item xs={12} sm={6} lg={12}>
                                    <UserSummary/>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={12}>
                                    <UserOrders/>
                                </Grid>
                                
                            </Grid>
                        </Grid> */}

          <Grid item xs={12} md={6} lg={6}>
            <NewConnections data={tecnicosPendentes} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <RecentActivities data={ultimasAtividades} />
          </Grid>

          <Grid item xs={12} md={6} lg={2.4}>
            <LastMonthSales
              value={contagemTickets ? contagemTickets.abertos.count : 0}
              data={resumoChamados}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2.4}>
            <OnlineSignupsFilled
              value={contagemTickets ? contagemTickets.resolvidos.count : 0}
              data={resumoChamados}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2.4}>
            <ChamadosResolvidosDiretos
              value={contagemTickets ? contagemTickets.emAndamento?.count : 0}
              data={resumoChamados}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={2.4}>
            <ProjectCounterCard />
          </Grid>  */}

          <Grid item xs={12} md={6} lg={2.4}>
            <NewVisitorsThisMonth
              value={contagemTickets ? contagemTickets.orfaos.count : 0}
              data={resumoChamados}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2.4}>
            <TotalRevenueThisYear
              value={contagemTickets ? contagemTickets.cancelados.count : 0}
              data={resumoChamados}
            />
          </Grid>

          {/* {<Grid item xs={12}>
                            <MarkerClusterer isExample={false}/>
                        </Grid>} */}
        </Grid>
      )}
    </>
  );
};

export default MiscDashboard;
